import NextI18Next from 'next-i18next';

const NextI18NextInstance = new NextI18Next({
  browserLanguageDetection: true,
  localePath: typeof window === 'undefined' ? 'public/static/locales' : 'static/locales',
  defaultLanguage: 'el',
  serverLanguageDetection: false,
  otherLanguages: ['el'],
  preload: ['el'],
  fallbackLng: 'el'
  // debug: process.env.NODE_ENV === 'development'
});

NextI18NextInstance.i18n.languages = ['el'];

export default NextI18NextInstance;

export const { appWithTranslation, i18n, withTranslation } = NextI18NextInstance;
